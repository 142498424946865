import {
  isMessagesEnabled,
  isNotesEnabled,
  isVideoEnabled,
  isDailyActionsEnabled,
  formatHeight,
  didInstalledMobileApp,
  isCoachIrisEnabled,
} from '@allurion/domain';
import {
  NoteIcon,
  Tabs,
  Tooltip,
  TrashCanIcon,
  useConfirmationDialog,
  VideoIcon,
} from '@allurion/ui';
import { Suspense, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useParams } from 'react-router-dom';

import { useTrackEvent } from 'src/analytics/analytics';
import { TrackedIconButton, TrackedPageHeader } from 'src/analytics/TrackedUI';
import { ErrorBoundary } from 'src/components/ErrorBoundary/ErrorBoundary';
import { Seo } from 'src/components/Seo';
import { InlineLoader } from 'src/components/ui/InlineLoader';
import { Loader } from 'src/components/ui/Loader';
import { useAppNavigate } from 'src/hooks/useAppNavigate';
import { useClinicPatients } from 'src/hooks/useClinicPatients';
import { useClinicSettings } from 'src/hooks/useClinicSettings';
import { useCurrentProvider } from 'src/hooks/useCurrentProvider';
import { usePatientOverview } from 'src/hooks/usePatientOverview';
import { usePatientProfile, deletePatient } from 'src/hooks/usePatientProfile';
import globalMessages from 'src/messages/global.messages';
import { useSetupTwilioConversation } from 'src/twilio/useSetupTwilioConversation';
import {
  KEY_CHAT,
  KEY_OVERVIEW,
  KEY_SCALE,
  KEY_ACTIVITY,
  PATIENT_PAGE_VALID_TABS,
  KEY_LIBRARY,
  KEY_DAILYACTIONS,
  KEY_COACH_IRIS_HISTORY,
} from 'src/utils/constants';
import env from 'src/utils/env';

import { PatientActivities } from './Activities/PatientActivities';
import { PatientChat } from './Chat/PatientChat';
import { CoachIrisHistory } from './CoachIrisHistory/CoachIrisHistory';
import { PatientContentLibrary } from './ContentLibrary/PatientContentLibrary';
import { PatientDailyActions } from './DailyActions/PatientDailyActions';
import { PatientOverview } from './Overview/PatientOverview';
import messageIntl from './patient-page.messages';
import { PatientScaleData } from './PatientScaleData/PatientScaleData';
import { PatientSidebar } from './PatientSidebar/PatientSidebar';
import { ToggleActiveInactive } from './ToggleActiveInactive';

import styles from './PatientPage.module.scss';

export default function PatientPage() {
  const { patientId } = useParams();
  const intl = useIntl();
  const location = useLocation();
  const { trackClick } = useTrackEvent();
  const { heightUnit } = useCurrentProvider();
  const { toAnchor: changeTab, toClinicPage } = useAppNavigate();
  const { askConfirmationPromise, ConfirmationDialog } = useConfirmationDialog();
  const { reload: reloadPatientData } = usePatientOverview(patientId);
  const { patient, reload: reloadPatientProfile } = usePatientProfile(patientId);
  const patientUsername = patient?.userid;
  const clinicId = patient?.hospitalid.toString();
  const { settings } = useClinicSettings(clinicId);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [activeSidebard, setActiveSidebar] = useState('');
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);
  const { reload: reloadClinicPatients } = useClinicPatients(clinicId, {
    suspense: false,
    showAll: false,
  });

  useSetupTwilioConversation(patient);

  const currTab = useMemo(() => {
    const selectedTab = PATIENT_PAGE_VALID_TABS.find((key) => location.hash.includes(key));

    return selectedTab || 'overview';
  }, [location]);

  if (!patientId || !clinicId) {
    return null;
  }

  const isPatientActive = patient.active_patient;

  const headerSubtitlePatientInfo = () => {
    const { age, height } = patient || {};

    let patientHeight = '';
    const patientAge = age ? `${age} ${intl.formatMessage(messageIntl.patientAge)},` : '';

    if (height) {
      patientHeight = patient && formatHeight(patient?.height, heightUnit);
    }

    return `${patientAge} ${patientHeight}`;
  };

  const isPatientAvailable = didInstalledMobileApp(patient);

  const tabsData = [
    {
      id: KEY_OVERVIEW,
      label: intl.formatMessage(messageIntl.overview),
      content: (
        <PatientOverview patientId={patientId} clinicId={clinicId} reloadData={reloadPatientData} />
      ),
    },
    {
      id: KEY_SCALE,
      label: intl.formatMessage(messageIntl.weight),
      content: <PatientScaleData patientId={patientId} reloadData={reloadPatientData} />,
    },
    {
      id: KEY_ACTIVITY,
      label: intl.formatMessage(messageIntl.activity),
      content: <PatientActivities patientId={patientId} />,
    },
    {
      id: KEY_CHAT,
      hide: !isMessagesEnabled(settings),
      label: intl.formatMessage(messageIntl.chat),
      content: (
        <PatientChat
          patient={patient}
          patientIdentity={patientUsername ?? ''}
          isPatientAvailable={isPatientAvailable}
        />
      ),
    },
    {
      id: KEY_LIBRARY,
      label: intl.formatMessage({ id: 'tab-menu.content-library', defaultMessage: 'Library' }),
      content: <PatientContentLibrary patientId={patientId} clinicId={clinicId} />,
      callback: trackLibraryGoogleAnalytics,
    },
    {
      id: KEY_DAILYACTIONS,
      hide: !isDailyActionsEnabled(settings),
      label: intl.formatMessage({
        id: messageIntl.dailyActions.id,
        defaultMessage: 'Daily Actions',
      }),
      content: <PatientDailyActions patientId={patientId} />,
      callback: trackDailyActionsGoogleAnalytics,
    },
    {
      id: KEY_COACH_IRIS_HISTORY,
      hide: !isCoachIrisEnabled(settings) || env.IS_PROD,
      label: intl.formatMessage({
        id: 'tab-menu.coach-iris-history',
        defaultMessage: 'Coach Iris History',
      }),
      content: <CoachIrisHistory patientId={patientId} />,
      callback: trackCoachIrisHistoryGoogleAnalytics,
    },
  ].filter((tab) => !tab.hide);

  return (
    <div key={patientId} className={styles.wrapper}>
      <ConfirmationDialog />
      <Seo title="Patient" />
      <Loader isLoading={isLoading} />

      <div className={styles.innerWrapper}>
        <div className={styles.pageHeader}>
          <TrackedPageHeader
            title={`${patient?.name} ${patient?.lastname}`}
            subtitle={headerSubtitlePatientInfo()}
            onNavButtonClick={() => toClinicPage(clinicId)}
            onNavButtonClickTrackLabel="back-to-clinic"
            hasBorder={false}
          />
          <div className={styles.pageHeader__content} data-sentry-unmask>
            <div className={styles.pageHeader__content__tabs}>
              <Tabs
                variant="primary"
                direction="row"
                tabs={tabsData}
                onTabChange={(tab) => changeTab(tab.id)}
                selectedTabId={currTab}
              />
            </div>
            <div className={styles.pageHeader__content__actions}>
              <Tooltip
                text={intl.formatMessage({
                  id: 'patient-page.remove-patient-tooltip',
                  defaultMessage: 'Remove patient',
                })}
                placement="bottom"
              >
                <TrackedIconButton
                  size="sm"
                  variant="secondaryDanger"
                  icon={<TrashCanIcon />}
                  onClick={handleDeletePatient}
                  trackLabel="remove-patient"
                />
              </Tooltip>
              <ToggleActiveInactive patient={patient} onChange={handleActivePatientUpdate} />
              &nbsp;
              {isVideoEnabled(settings) && (
                <Tooltip
                  text={intl.formatMessage({
                    id: 'patient-page.video-sidepanel-tooltip',
                    defaultMessage: 'Video',
                  })}
                  placement="bottom"
                >
                  <TrackedIconButton
                    size="sm"
                    variant="secondary"
                    icon={<VideoIcon />}
                    onClick={() => openSidebar('video')}
                    active={activeSidebard === 'video'}
                    trackLabel="open-video-calls-panel"
                    disabled={!isPatientActive}
                  />
                </Tooltip>
              )}
              {isNotesEnabled(settings) && (
                <Tooltip
                  text={intl.formatMessage({
                    id: 'patient-page.notes-sidepanel-tooltip',
                    defaultMessage: 'Notes',
                  })}
                  placement="bottom"
                >
                  <TrackedIconButton
                    size="sm"
                    variant="secondary"
                    icon={<NoteIcon />}
                    onClick={() => openSidebar('notes')}
                    active={activeSidebard === 'notes'}
                    trackLabel="open-patient-notes-panel"
                    disabled={!isPatientActive}
                  />
                </Tooltip>
              )}
            </div>
          </div>
        </div>
        <div className={styles.innerContainer}>
          {!isPatientActive && <div className={styles.blocked} />}
          <ErrorBoundary>
            {tabsData.map(({ id, content }) => (
              <div className={currTab === id ? styles.content : styles.content__hide} key={id}>
                <Suspense fallback={<InlineLoader />}>{content}</Suspense>
              </div>
            ))}
          </ErrorBoundary>
        </div>
      </div>

      <PatientSidebar
        isOpen={isSidebarOpen}
        setActiveButton={setActiveSidebar}
        setIsOpen={setIsSidebarOpen}
        selectedButton={activeSidebard}
      />
    </div>
  );

  function openSidebar(buttonName: string) {
    if (activeSidebard === buttonName) {
      setActiveSidebar('');
      setIsSidebarOpen(false);

      return;
    }

    setActiveSidebar(buttonName);
    setIsSidebarOpen(true);
  }

  async function handleDeletePatient() {
    if (!patientId) {
      return;
    }

    const shouldDeletePatient = await askConfirmationPromise({
      title: intl.formatMessage({
        id: 'patient-profile.delete-patient-dialog-title',
        defaultMessage: 'Are you sure you want to remove this patient from all clinics?',
      }),
      message: (
        <>
          {intl.formatMessage({
            id: 'patient-profile.delete-patient-dialog-message',
            defaultMessage: 'This patient will no longer appear in any clinics.',
          })}
          <br />
          <br />
          {intl.formatMessage({
            id: 'patient-profile.delete-patient-dialog-second-message',
            defaultMessage:
              'If you know the correct clinic for this patient, please contact help@allurion.com.',
          })}
          <br />
          <br />
        </>
      ),
      confirmText: intl.formatMessage({
        id: 'patient-profile.delete-patient-dialog-confirm-btn',
        defaultMessage: 'Remove patient',
      }),
      cancelText: intl.formatMessage(globalMessages.cancel),
      variant: 'danger',
    });

    if (!shouldDeletePatient) {
      return;
    }
    setIsLoading(true);
    await deletePatient(patientId);
    await reloadClinicPatients();
    toClinicPage(clinicId);
  }

  function trackLibraryGoogleAnalytics(key: string) {
    // NOTE: location.hash is the hash prior to the click event.
    // i.e., if you are in the video tab, and click on the library tab,
    // then location.hash.slice(1) === 'video'
    if (location.hash?.slice(1) !== key) {
      if (key === KEY_LIBRARY) {
        trackClick(null, {
          label: 'patient-library-tab',
          patientId,
        });
      }
    }
  }

  function trackDailyActionsGoogleAnalytics(key: string) {
    if (location.hash?.slice(1) !== key) {
      if (key === KEY_DAILYACTIONS) {
        trackClick(null, {
          label: 'patient-daily-actions-tab',
          patientId,
        });
      }
    }
  }

  function trackCoachIrisHistoryGoogleAnalytics(key: string) {
    if (location.hash?.slice(1) !== key) {
      if (key === KEY_COACH_IRIS_HISTORY) {
        trackClick(null, {
          label: 'patient-coach-iris-history-tab',
          patientId,
        });
      }
    }
  }

  async function handleActivePatientUpdate() {
    await reloadPatientProfile();
    await reloadClinicPatients();
  }
}
