import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { User } from 'src/domain/User';
import { RootState } from 'src/state/store';

type UserSliceState = {
  user: User;
};

const initialState: UserSliceState = {
  user: {},
};

export const userSlice: any = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, { payload: user }: PayloadAction<User>) {
      return {
        ...state,
        user,
      };
    },
    clearAll() {
      return initialState;
    },
  },
});

export const UserActions = userSlice.actions;

export function getCurrentUser(state: RootState) {
  return (state as any).user?.user;
}
