export function scrollToTop() {
  return typeof window !== 'undefined' && window.scrollTo(0, 0);
}

export function scrollIntoView(element: HTMLElement | null) {
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
}

export function classNames(...classes: (string | undefined | false | null)[]) {
  return classes.filter(Boolean).join(' ');
}
