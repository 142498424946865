import { Suspense, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import 'src/utils/polyfills/getUserMedia';

import { AnalyticsProvider } from 'src/analytics/analytics';
import { useTrackPageView } from 'src/analytics/useTrackPageView';
import { ErrorBoundary } from 'src/components/ErrorBoundary/ErrorBoundary';
import { Header } from 'src/components/Header/Header';
import { MessageNotification } from 'src/components/MessageNotification';
import { Seo } from 'src/components/Seo';
import { useCurrentUser } from 'src/hooks/useCurrentUser';
import { Logger } from 'src/services/Logger';
import { GENERIC_TOAST_ID } from 'src/utils/constants';
import env from 'src/utils/env';

import { SessionTimeout } from './SessionTimeout';
import { InlineLoader } from './ui/InlineLoader';

export function Layout() {
  const { user } = useCurrentUser();

  useTrackPageView();

  useEffect(() => {
    if (user.username) {
      Logger.configureScope(user);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.username]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Allurion Insights</title>
        <script async src={`https://www.googletagmanager.com/gtag/js?id=${env.GTM_ID}`} />
        <script>
          {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());

              gtag('config', "${env.GTM_ID}");
          `}
        </script>
      </Helmet>

      <Seo title="Allurion Insights" />
      <ErrorBoundary>
        <AnalyticsProvider>
          <Outlet />
        </AnalyticsProvider>
      </ErrorBoundary>
      <ToastContainer containerId={GENERIC_TOAST_ID} enableMultiContainer theme="colored" />
    </>
  );
}

export function AuthenticatedLayout() {
  return (
    <ErrorBoundary>
      <Header />
      <SessionTimeout />
      <main role="main">
        <ErrorBoundary>
          <Suspense fallback={<InlineLoader />}>
            <Outlet />
          </Suspense>
        </ErrorBoundary>
      </main>
      <MessageNotification />
    </ErrorBoundary>
  );
}
