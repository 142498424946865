/* eslint-disable @typescript-eslint/no-unsafe-function-type */
import { useState } from 'react';
import { useIntl } from 'react-intl';

import { StyledSelect } from 'src/components/ui/ReactSelect';
import { VolumeIcon } from 'src/icons/VolumeIcon';
import { Label } from 'src/styles/components';
import { AUDIO_OUTPUT_ID } from 'src/utils/constants';

import { DeviceOption } from '../video-call-types';
import messages from '../video-call.messages';

import { SpeakerContainer } from './styles';

type Props = {
  speakers: MediaDeviceInfo[];
  onSelection: Function;
};

export function SpeakerSelector({ speakers, onSelection = () => {} }: Props) {
  const [speaker, setSpeaker] = useState<DeviceOption | null>(null);
  const intl = useIntl();

  const onChange = (device: DeviceOption) => {
    const selectedDevice = {
      ...speaker,
      kind: 'audiooutput',
    };

    onSelection(selectedDevice);
    setSpeaker(device);
  };

  const message = intl.formatMessage(messages.speakers);
  const selectPlaceholder = intl.formatMessage(messages.speakersSelectorPlaceholder);
  const noDeviceMessage = intl.formatMessage(messages.noDevices);
  const isDisabled = speakers?.length < 1;
  const options = speakers?.map(({ deviceId, label }) => ({
    id: deviceId,
    value: deviceId,
    label,
  }));

  const value = speaker || noDeviceMessage;
  const isSpeakerAvailable = !!speakers.length;

  if (!isSpeakerAvailable) {
    return null;
  }

  return (
    <SpeakerContainer>
      <Label htmlFor={AUDIO_OUTPUT_ID}>
        <VolumeIcon width={16} height={16} strokeWidth={2.5} />
        {message}
      </Label>
      <StyledSelect
        id={AUDIO_OUTPUT_ID}
        isDisabled={isDisabled}
        onChange={onChange}
        options={options}
        isSearchable={false}
        value={value}
        placeholder={selectPlaceholder}
      />
    </SpeakerContainer>
  );
}
