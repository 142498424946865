import { PatientCoachIrisHistory } from '@allurion/domain';
import { scrollIntoView } from '@allurion/ui';
import { useLayoutEffect, useRef, useState } from 'react';

import { useCoachIrisHistory } from 'src/hooks/useCoachIrisHistory';
import { useIsVisible } from 'src/hooks/useIsVisible';

import styles from './CoachIrisHistory.module.scss';

type Props = {
  patientId: string;
};

export function CoachIrisHistory({ patientId }: Props) {
  const [firstLoad, setFirstLoad] = useState(true);
  const { messages, loadMore, isLoadingMore, isReachingEnd } = useCoachIrisHistory(patientId);
  const ref = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const isContainerVisible = useIsVisible(containerRef);

  useLayoutEffect(() => {
    if (firstLoad && isContainerVisible && messages.length > 0) {
      setFirstLoad(false);
      setTimeout(() => {
        scrollIntoView(ref.current);
      }, 500);
    }
  }, [firstLoad, isContainerVisible, messages]);

  return (
    <div className={styles.container} ref={containerRef}>
      <div className={styles.innerContainer}>
        <button
          type="button"
          onClick={loadMore}
          disabled={isLoadingMore || isReachingEnd}
          className={styles.loadMoreButton}
        >
          {isLoadingMore ? 'loading...' : isReachingEnd ? 'end of history' : 'Load more'}
        </button>
        {messages.map((message) => (
          <div
            key={message.timestamp}
            className={[
              styles.message,
              isUserMessage(message) ? styles.patientMessage : styles.coachIrisMessage,
            ]
              .filter(Boolean)
              .join(' ')}
          >
            <div className={styles.header}>
              {isUserMessage(message) ? (
                ''
              ) : (
                <>
                  <span className={styles.icon}>I</span>
                  <span className={styles.user}>Coach Iris • </span>
                </>
              )}
              <div className={styles.timestamp}>{formatTimestamp(message.timestamp)}</div>
            </div>

            <div className={styles.content}>{message.content}</div>
          </div>
        ))}
        <div ref={ref}></div>
      </div>
    </div>
  );

  function formatTimestamp(timestamp: number | undefined) {
    if (!timestamp) {
      return '';
    }

    return new Date(timestamp).toLocaleString();
  }
}
function isUserMessage(message: PatientCoachIrisHistory) {
  return message.source === 'user';
}
