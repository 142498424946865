type CfAsset = {
  file?: {
    url: string;
  };
};

export type CfDailyAction = {
  _id: string;
  _contentType: string;
  title: string;
  icon: CfAsset;
  question: string;
  description: string;
};

export type CfArticle = {
  _id: string;
  title: string;
  subtitle?: string;
  notificationSubtitle?: string;
  thumbnail?: CfAsset;
  readTime?: string;
  url: string;
  header?: {
    asset?: CfAsset;
  };
};

export type Article = {
  collectionId: string;
  id: string;
  title: string;
  url: string;
  deliveryDate?: string;
  notificationDate?: string;
  sentDate?: string;
  viewDate?: string;
  libraryUrl?: string;
};

export type CfArticleCollection = {
  _id: string;
  title: string;
  articles?: CfArticle[];
  treatmentSubcategory?: string;
  allowSubscription?: boolean;
  clinicsWithAccess?: string[];
  libraryUrl?: string;
  backgroundColor?: string;
  patientFacingTitle?: string;
  subtitle?: string;
  icon?: CfAsset;
};

export type ArticleCollection = {
  id: string;
  title: string;
  locale: string;
  libraryUrl?: string;
  backgroundColor?: string;
  patientFacingTitle?: string;
  subtitle?: string;
  icon?: string;
  treatmentSubcategory?: string;
  allowSubscription: boolean;
  clinicsWithAccess: string[];
  articles: {
    id: string;
    title: string;
    url: string;
  }[];
};

export function buildContentUrl(
  { url }: { url: string },
  locale: string,
  hostname = 'https://cms.allurion.com'
) {
  return `${hostname}/${locale}${url}`;
}

export function getArticleMetadata(article: CfArticle, locale: string) {
  return {
    id: article._id,
    title: article.title,
    subtitle: article.subtitle,
    url: buildContentUrl(article, locale, process.env['CMS_HOST_NAME']),
    image: withHttpsPrefix(article.header?.asset?.file?.url),
    notificationSubtitle: article.notificationSubtitle,
    thumbnail: withHttpsPrefix(article.thumbnail?.file?.url),
    readTime: article.readTime,
    locale,
  };
}

export function withHttpsPrefix(url?: string) {
  if (!url) {
    return url;
  }

  if (url.startsWith('http')) {
    return url;
  }

  return `https:${url}`;
}

export function canBeUsedByClinic({ clinicsWithAccess }: ArticleCollection, clinicId: string) {
  return Boolean(clinicsWithAccess.length === 0 || clinicsWithAccess.includes(clinicId));
}

export function isCompatibleWithTreatmentSubcategories(
  { treatmentSubcategory }: ArticleCollection,
  treatmentSubcategories: string[]
) {
  return Boolean(!treatmentSubcategory || treatmentSubcategories.includes(treatmentSubcategory));
}
