import { isJwtTokenExpired, isString } from '@allurion/utils';

import { fetchTwilioToken } from './TwilioApi';

export const TWILIO_KEY_TOKEN = 'tw-conversations-token';

export function clearTwilioToken() {
  localStorage.removeItem(TWILIO_KEY_TOKEN);
}

export function saveTwilioToken(token: string, identity: string) {
  localStorage.setItem(TWILIO_KEY_TOKEN, JSON.stringify({ token, identity }));
}

export function getSavedTwilioToken() {
  const savedToken = localStorage.getItem(TWILIO_KEY_TOKEN);

  if (!savedToken) {
    return {};
  }

  try {
    const { token, identity } = JSON.parse(savedToken);

    if (!isString(token) || !isString(identity) || isJwtTokenExpired(token)) {
      clearTwilioToken();

      return {};
    }

    return { token, identity };
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (error) {
    return {};
  }
}

export async function getTwilioToken(username: string) {
  const { identity, token: savedToken } = getSavedTwilioToken();

  if (username === identity) {
    return savedToken;
  }

  const token = await fetchTwilioToken(username);

  saveTwilioToken(token, username);

  return token;
}
